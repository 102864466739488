// src/PFPGenerator.js
import React, { useState, useRef } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Canvg } from 'canvg';
import PFPImageSection from './PFPImageSection';
import colorThemes from './colorThemes';
import './PFPGenerator.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    default: {
      main: '#e0e0e0',
    },
  },
  typography: {
    fontFamily: "'VT323', monospace",
  },
});

const categories = ['body', 'shirt', 'mouth', 'eyes', 'headwear', 'special'];

const svgFiles = {
  body: ['Pork.svg', 'Pepe.svg'],
  shirt: ['Blue.svg'],
  mouth: ['Pursed.svg', 'Normal.svg'],
  eyes: ['Right.svg', 'Left.svg', 'Shades.svg'],
  headwear: ['Blonde.svg', 'Durag.svg', 'BlackHair.svg', 'WifHat.svg', 'Crown.svg', 'Cowboy.svg'],
  special: ['Ciggy.svg'],
};

const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

const PFPGenerator = () => {
  const [traits, setTraits] = useState({
    body: 'Pepe.svg',
    shirt: 'Blue.svg',
    mouth: 'Normal.svg',
    eyes: 'Left.svg',
    headwear: '',
    special: '',
  });

  const [backgroundColor, setBackgroundColor] = useState('#87CEEB'); // Default to Sky Blue
  const [selectedTheme, setSelectedTheme] = useState('Light Mode'); // Default theme
  const canvasRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTraits((prevTraits) => ({
      ...prevTraits,
      [name]: value,
    }));
  };

  const getFilePath = (category, fileName) => {
    return `/pfp-art/${category}/${fileName}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xs" className="pfp-generator">
        <Box my={2} textAlign="center">
          <Typography variant="h5" gutterBottom align="center">
            8-bit $PEPE PFP Generator
          </Typography>
          <div className="pfp-container">
            <canvas ref={canvasRef} width={500} height={500} style={{ display: 'none' }}></canvas>
            <PFPImageSection 
              traits={traits} 
              backgroundColor={backgroundColor} 
              setBackgroundColor={setBackgroundColor}
              selectedTheme={selectedTheme} 
              setSelectedTheme={setSelectedTheme} 
              getFilePath={getFilePath} 
              setTraits={setTraits}
              svgFiles={svgFiles}
              handleRandomize={() => {
                const newTraits = {
                  body: getRandomItem(svgFiles.body),
                  shirt: getRandomItem(svgFiles.shirt),
                  mouth: getRandomItem(svgFiles.mouth),
                  eyes: getRandomItem(svgFiles.eyes),
                  headwear: getRandomItem(['', ...svgFiles.headwear]),
                  special: getRandomItem(['', ...svgFiles.special]),
                };
                const randomColor = getRandomItem(colorThemes[selectedTheme]).color;
                setTraits(newTraits);
                setBackgroundColor(randomColor);
              }}
              handleReset={() => {
                setTraits({
                  body: 'Pepe.svg',
                  shirt: 'Blue.svg',
                  mouth: 'Normal.svg',
                  eyes: 'Left.svg',
                  headwear: '',
                  special: '',
                });
                setBackgroundColor('#87CEEB');
                setSelectedTheme('Light Mode');
              }}
              handleExport={async () => {
                const finalCanvas = canvasRef.current;
                const finalContext = finalCanvas.getContext('2d');
                finalContext.clearRect(0, 0, finalCanvas.width, finalCanvas.height); // Clear canvas before drawing
                finalContext.fillStyle = backgroundColor;
                finalContext.fillRect(0, 0, finalCanvas.width, finalCanvas.height);
                finalContext.imageSmoothingEnabled = false; // Disable image smoothing

                const renderLayer = async (category, fileName) => {
                  if (!fileName) return;
                  const response = await fetch(getFilePath(category, fileName));
                  const svgText = await response.text();
                  const tempCanvas = document.createElement('canvas');
                  tempCanvas.width = finalCanvas.width;
                  tempCanvas.height = finalCanvas.height;
                  const tempContext = tempCanvas.getContext('2d');
                  const v = Canvg.fromString(tempContext, svgText);
                  await v.render();
                  finalContext.drawImage(tempCanvas, 0, 0, finalCanvas.width, finalCanvas.height);
                };

                // Render each layer in order
                for (const category of categories) {
                  await renderLayer(category, traits[category]);
                }

                const link = document.createElement('a');
                link.href = finalCanvas.toDataURL('image/png');
                link.download = 'pfp.png';
                link.click();
              }}
            />
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PFPGenerator;
