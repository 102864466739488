// src/PFPImageSection.js
import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import colorThemes from './colorThemes';

const PFPImageSection = ({ traits, backgroundColor, setBackgroundColor, getFilePath, setTraits, svgFiles, handleRandomize, handleReset, handleExport }) => {
  const categories = ['body', 'shirt', 'mouth', 'eyes', 'headwear', 'special'];

  const handleTraitChange = (category, value) => {
    setTraits((prevTraits) => ({
      ...prevTraits,
      [category]: value,
    }));
  };

  return (
    <Box my={2} textAlign="center">
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <div className="pfp-viewer" style={{ backgroundColor }}>
            {categories.map((category) => (
              traits[category] && (
                <img
                  key={category}
                  src={getFilePath(category, traits[category])}
                  alt={category}
                  className="svg-image"
                />
              )
            ))}
          </div>
        </Grid>
        <Grid item xs={3} display="flex" flexDirection="column" justifyContent="center">
          <IconButton onClick={handleRandomize} color="primary" aria-label="randomize">
            <ShuffleIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleReset} color="secondary" aria-label="reset">
            <ReplayIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleExport} color="default" aria-label="export">
            <SaveAltIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      <Box my={2}>
        <Grid container spacing={2}>
          {categories.map((category) => (
            <Grid item xs={4} key={category}>
              <div className="dropdown">
                <div className="dropdown-selected">
                  {traits[category] ? (
                    <img src={getFilePath(category, traits[category])} alt={category} className="thumbnail" />
                  ) : (
                    <ImageIcon />
                  )}
                </div>
                <div className="dropdown-content">
                  <div onClick={() => handleTraitChange(category, '')}>
                    <ImageIcon />
                  </div>
                  {svgFiles[category].map((file) => (
                    <div key={file} onClick={() => handleTraitChange(category, file)}>
                      <img src={getFilePath(category, file)} alt={file} className="thumbnail" />
                      {file.replace('.svg', '')}
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default PFPImageSection;
