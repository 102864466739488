import React from 'react';
import './DexscreenerEmbed.css';

const DexscreenerEmbed = () => {
  return (
    <div id="dexscreener-embed">
      <iframe
        src="https://dexscreener.com/solana/83voyekvMhSGrzbxE6tE47YeCa7peawqkmkpuGaQKwys?embed=1&theme=dark&trades=0"
        title="Dexscreener"
      ></iframe>
    </div>
  );
};

export default DexscreenerEmbed;
