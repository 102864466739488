// src/TokenDetails.js
import React from 'react';
import { Container, Typography, Box, Link, IconButton, Paper, Grid } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

const TokenDetails = () => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard: ' + text);
  };

  return (
    <Container maxWidth="sm" className="token-details">
      <Box my={4}>
        <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
          <Typography variant="h4" gutterBottom>
            ABOUT
          </Typography>
          <Typography variant="body1" gutterBottom>
            In the vast, ever-expanding universe of memes, one character stands out as the true pioneer: Pepe the Frog. Born in the early days of internet culture, Pepe became an emblem of online communities, bringing joy, laughter, and sometimes controversy. As the meme world evolved, so did Pepe, transcending into countless forms and iterations. But now, Pepe returns in a new, immutable form as the 8-bit Pepe Coin.
          </Typography>
        </Paper>
      </Box>
      <Box my={4}>
        <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
          <Typography variant="h4" gutterBottom>
            LINKS
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Link href="https://dexscreener.com/solana/83voyekvMhSGrzbxE6tE47YeCa7peawqkmkpuGaQKwys" target="_blank" rel="noopener">
                Dexscreener
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link href="https://www.dextools.io/app/en/solana/pair-explorer/83voyekvMhSGrzbxE6tE47YeCa7peawqkmkpuGaQKwys?t=1722717315122" target="_blank" rel="noopener">
                Dextools
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link href="https://t.me/EightBitPepePortal" target="_blank" rel="noopener">
                Telegram
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link href="https://x.com/real8bitpepe" target="_blank" rel="noopener">
                Twitter
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box my={4}>
        <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
          <Typography variant="h4" gutterBottom>
            TOKEN DETAILS
          </Typography>
          <Typography variant="body1" gutterBottom>
            Launched as a test on pump.fun and shared with friends, 8-bit Pepe Coin quickly took off on the Solana blockchain, amassing many holders and building a vibrant community. In a stroke of serendipity, the token's contract address ends with "8bit" and the deployer wallet ends with "pepe", emphasizing its unique identity in the Pepe-verse.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Contract Address: 
            <Link href="https://solscan.io/token/8t59RDhHiYva8yDYEAtbQ1Dv1BZzFerGDeadesZG8bit" target="_blank" rel="noopener">
              8t59RDhHiYva8yDYEAtbQ1Dv1BZzFerGDeadesZG8bit
            </Link>
            <IconButton onClick={() => handleCopy('8t59RDhHiYva8yDYEAtbQ1Dv1BZzFerGDeadesZG8bit')}>
              <ContentCopy fontSize="small" />
            </IconButton>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Deployer Wallet: 
            <Link href="https://solscan.io/account/G7WM9D9RVUk4K2beRUUd7hAcNFJwui3y19Kedj3rPEPe" target="_blank" rel="noopener">
              G7WM9D9RVUk4K2beRUUd7hAcNFJwui3y19Kedj3rPEPe
            </Link>
            <IconButton onClick={() => handleCopy('G7WM9D9RVUk4K2beRUUd7hAcNFJwui3y19Kedj3rPEPe')}>
              <ContentCopy fontSize="small" />
            </IconButton>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Total Supply: 1,000,000,000 tokens
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default TokenDetails;
