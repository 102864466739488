// src/colorThemes.js
const colorThemes = {
    'Light Mode': [
      { name: 'Sky Blue', color: '#87CEEB' },
      { name: 'Light Yellow', color: '#FFFFE0' },
      { name: 'Light Green', color: '#90EE90' },
      { name: 'Light Coral', color: '#F08080' },
      { name: 'Light Pink', color: '#FFB6C1' },
      { name: 'Light Gray', color: '#D3D3D3' },
      { name: 'Lavender', color: '#E6E6FA' },
      { name: 'Light Cyan', color: '#E0FFFF' },
      { name: 'Peach Puff', color: '#FFDAB9' },
      { name: 'Light Salmon', color: '#FFA07A' },
    ],
    'Dark Mode': [
      { name: 'Midnight Blue', color: '#191970' },
      { name: 'Dark Slate Gray', color: '#2F4F4F' },
      { name: 'Dim Gray', color: '#696969' },
      { name: 'Black', color: '#000000' },
      { name: 'Dark Olive Green', color: '#556B2F' },
      { name: 'Dark Red', color: '#8B0000' },
      { name: 'Dark Magenta', color: '#8B008B' },
      { name: 'Dark Goldenrod', color: '#B8860B' },
      { name: 'Dark Cyan', color: '#008B8B' },
      { name: 'Dark Violet', color: '#9400D3' },
    ],
    'Vibrant': [
      { name: 'Hot Pink', color: '#FF69B4' },
      { name: 'Electric Blue', color: '#7DF9FF' },
      { name: 'Lime Green', color: '#32CD32' },
      { name: 'Orange Red', color: '#FF4500' },
      { name: 'Gold', color: '#FFD700' },
      { name: 'Chartreuse', color: '#7FFF00' },
      { name: 'Deep Pink', color: '#FF1493' },
      { name: 'Dodger Blue', color: '#1E90FF' },
      { name: 'Magenta', color: '#FF00FF' },
      { name: 'Cyan', color: '#00FFFF' },
    ],
    'Pastel': [
      { name: 'Lavender Blush', color: '#FFF0F5' },
      { name: 'Pale Turquoise', color: '#AFEEEE' },
      { name: 'Light Salmon', color: '#FFA07A' },
      { name: 'Misty Rose', color: '#FFE4E1' },
      { name: 'Light Goldenrod Yellow', color: '#FAFAD2' },
      { name: 'Thistle', color: '#D8BFD8' },
      { name: 'Light Pink', color: '#FFB6C1' },
      { name: 'Powder Blue', color: '#B0E0E6' },
      { name: 'Peach Puff', color: '#FFDAB9' },
      { name: 'Mint Cream', color: '#F5FFFA' },
    ],
    'Retro': [
      { name: 'Turquoise', color: '#40E0D0' },
      { name: 'Tomato', color: '#FF6347' },
      { name: 'Medium Purple', color: '#9370DB' },
      { name: 'Khaki', color: '#F0E68C' },
      { name: 'Pale Green', color: '#98FB98' },
      { name: 'Coral', color: '#FF7F50' },
      { name: 'Sandy Brown', color: '#F4A460' },
      { name: 'Slate Blue', color: '#6A5ACD' },
      { name: 'Rosy Brown', color: '#BC8F8F' },
      { name: 'Plum', color: '#DDA0DD' },
    ],
  };
  
  export default colorThemes;
  