// App.js
import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import DexscreenerEmbed from './DexscreenerEmbed';
import TokenDetails from './TokenDetails';
import PFPGenerator from './PFPGenerator';

const theme = createTheme({
  typography: {
    fontFamily: "'VT323', monospace",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="app">
                <Container maxWidth="sm" className="content">
                  <Box my={4} textAlign="center">
                    <img src="/images/8bitLogo.png" alt="8-Bit $PEPE" className="logo" />
                    <Typography variant="h4" gutterBottom align="center">
                      Resolution Low, Vibes High
                    </Typography>
                    <Button
                      component={Link}
                      to="/pfp-generator"
                      variant="contained"
                      sx={{
                        mt: 4, // Increased margin top for better spacing
                        mb: 4, // Added margin bottom for spacing from Dexscreener
                        px: 4, // Increased padding for larger button
                        py: 2, // Increased padding for larger button
                        backgroundColor: '#FFD700',
                        color: '#000',
                        border: '2px solid #000',
                        borderRadius: '8px',
                        fontSize: '1.5rem', // Increased font size
                        fontFamily: "'VT323', monospace",
                        '&:hover': {
                          backgroundColor: '#FFC700',
                        },
                      }}
                    >
                      Go to PFP Generator
                    </Button>
                    <DexscreenerEmbed />
                  </Box>
                </Container>
                <TokenDetails />
                <div className="ground"></div>
              </div>
            }
          />
          <Route path="/pfp-generator" element={<PFPGenerator />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
